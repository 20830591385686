.MuiDataGrid-root {
  background-color: white;
  border: 1px solid #c9c9d1!important;
  border-top: none !important;
  border-radius: 0px 0px 3px 3px!important;
  box-shadow: 0 30px 70px 0 rgba(226,232,236,0.5)!important;
}

.MuiDataGrid-header {
  background-color: #f9fafb;
  border-radius: 3px 3px 0px 0px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.MuiDataGrid-row {
  cursor: pointer !important;
}

.MuiDataGrid-colCellWrapper {
  background-color: #f9fafb;
  align-items: flex-start!important;
}

.MuiDataGrid-cell {
  justify-content: center!important;
}

.MuiDataGrid-window {
  overflow-x: hidden!important;
}

.MuiDataGrid-footer {
  background-color: #f9fafb;
  border-radius: 3px;
}

.MuiAutocomplete-paper {
  padding: 0px!important
}

html {
  scroll-behavior: smooth;
  background-color: #f1f3f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: Prometo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
